import Vue from 'vue'
import VueResource from 'vue-resource'
Vue.use(VueResource)
export default{
  // baseUrl: 'http://192.168.2.11:8119/api',
  baseUrl: 'https://center.istudyedu.com/api/ssg',
  Accept: 'application/x.istudyerp.v1+json',
  contenttype: 'application/x-www-form-urlencoded',
  get: function (options) {
		this.request('get', options, this.baseUrl)
	},
	//接口调取post方法
	post: function (options) {
		this.request('post', options, this.baseUrl)
	},
	request(method, options, url){
    let token = 'bearer ' + window.localStorage.getItem('ssg-phone-token')
    Vue.http.headers.common['Authorization'] = token
    Vue.http.headers.common['Accept'] = this.Accept
    Vue.http.headers.common['Content-Type'] = this.contenttype
    Vue.http.options.emulateJSON = true
		let urls = url + '/' + options.url
		if (options.data === undefined) {
			options.data = {}
		}
    let params = {
      url: urls,
      params: options.data,
      source: options.source ? options.source : ''
    }
    let api = method === 'post' ? Vue.http.post(urls, params.params) : Vue.http.get(urls, params)
		api.then(function (response) {
      let res = response.body
      if (!isNaN(res.code)) { // 状态码强制转为整型
        res.code = Number(res.code)
      }
        typeof options.success === 'function' && options.success(res)
      }, function (response) {
        if (response.status === 401) {
          window.history.go(-1)
          // token过期 删除token 删除用户数据信息
          window.localStorage.removeItem('ssg-phone-token')
          window.localStorage.removeItem('ssg-phone-user-info')
          alert(response.body.message)
        }
        typeof options.fail === 'function' && options.fail(response.body)
    })
	}
}
