<template>
  <div class="public-login" v-if="isShow">
    <div class="login-box">
      <div class="box-btn" @click="goPage('/login')">
        <div class="btn-info">
          <i class="btn-icon login"></i>
          <span class="btn-text">Log in</span>
        </div>
      </div>
      <div class="box-btn" @click="goPage('/signup')">
        <div class="btn-info">
          <i class="btn-icon sign"></i>
          <span class="btn-text">Sign up</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'publicLogin',
  props: {},
  data () {
    return {
      isShow: true
    }
  },
  components: {},
  computed: {},
  created () {
    this.getIsShow()
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler () {
        this.getIsShow()
      }
    }
  },
  methods: {
    goPage (url) {
      this.$router.push({
        path: url
      })
    },
    getIsShow () {
      let userName = JSON.parse(window.localStorage.getItem('ssg-phone-user-info')) ? JSON.parse(window.localStorage.getItem('ssg-phone-user-info')).username : ''
      if (userName !== '' || this.$route.name === 'login' || this.$route.name === 'signup' || this.$route.name === 'myIndex') {
        this.isShow = false
      } else {
        this.isShow = true
      }
    }
  }
}
</script>

<style scoped>
.public-login{position:fixed;width:100%;max-width:720px;margin:0 auto;left:0;right:0;bottom:20px;box-sizing:border-box;padding:0 20px;}
.public-login .login-box{overflow:hidden;border-radius:38px;background:-webkit-linear-gradient(left, #825afb, #b0332f);background: -o-linear-gradient(right, #825afb, #b0332f); background: -moz-linear-gradient(right, #825afb, #b0332f);background: linear-gradient(to right, #825afb, #b0332f);}
.public-login .login-box .box-btn{float:left;position:relative;width:50%;height:38px;box-sizing:border-box;border-right:2px solid #fff;color:#fff;font-size:14px;margin:0 auto;}
.public-login .login-box .box-btn:last-child{border-right:none;}
.public-login .login-box .box-btn .btn-info{position:absolute;left:50%;top:50%;transform:translate(-50%,-50%);}
.public-login .login-box .box-btn .btn-icon{display:block;float:left;width:24px;height:24px;margin:0 auto;background:url('~@/assets/images/public-icon-login.png') center no-repeat;background-size:16px;margin-right:10px;}
.public-login .login-box .box-btn .btn-icon.sign{background-image:url('~@/assets/images/public-icon-sign-up.png');}
.public-login .login-box .box-btn .btn-text{display:inline-block;line-height:24px;}
</style>
