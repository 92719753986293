<template>
  <div class="my-index wrapper">
    <div class="my-content">
      <img class="content-img" :src="info.ssg_avatar ? info.ssg_avatar : activeImg" alt=""/>
      <p class="content-name">{{ info.username ?  info.username : 'Welcome to ss-group ! Please log in!'}}</p>
      <p class="content-btn">
        <span class="btn-self" @click="submit">{{ info.username ? 'loginOut' : 'login' }}</span>
      </p>
    </div>
    <footerIndex></footerIndex>
    <footerNav :type="'my'" ></footerNav>
  </div>
</template>

<script>
import footerNav from "@/components/public/footerNav.vue"
import activeImg from "@/assets/images/nav-my-active.png"
import footerIndex from "@/components/public/footer.vue"
export default {
  name: 'myIndex',
  props: {},
  data () {
    return {
      activeImg,
      info: {}
    }
  },
  components: {
    footerNav,
    footerIndex
  },
  computed: {
  },
  created () {
    this.info = JSON.parse(window.localStorage.getItem('ssg-phone-user-info')) ? JSON.parse(window.localStorage.getItem('ssg-phone-user-info')) : {}
  },
  watch: {},
  methods: {
    submit () {
      if (Object.keys(this.info).length > 0) {
        window.localStorage.removeItem('ssg-phone-user-info')
      }
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style scoped>
.my-index{background-color:#fff;height:100vh;}
.content-img{display: block;width:100px;overflow:hidden;margin: 20px auto;}
.content-name{text-align:center;font-size:16px;color: #333;font-weight:600;}
.content-btn{margin-top:20px;text-align: center;}
.content-btn .btn-self{padding:10px 20px;background-color:#eb8582;color:#fff;border-radius:20px;}
</style>
