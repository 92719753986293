<template>
  <div class="login-index wrapper">
    <nut-navbar @on-click-back="back" :leftShow="true" :rightShow="false">Login</nut-navbar>
    <div class="login-box">
      <div class="login-item">
        <div class="item-list">
          <p class="list-title">Username or Email</p>
          <nut-textinput  v-model="info.email" placeholder=" Please enter your username or email" :clearBtn="true" :disabled="false" />
        </div>
        <div class="item-list">
          <p class="list-title">Password</p>
          <nut-textinput  v-model="info.password" placeholder="Please enter your password" type="password" :clearBtn="true" :disabled="false" />
        </div>
      </div>
      <div class="login-operate">
        <span class="operate-sign" @click="goPage('/signup')">Sign up here</span>
      </div>
      <div class="sign-bottom">
      </div>
    </div>
    <div class="bottom-box">
      <div class="bottom-btn" @click="login">Log in</div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'loginIndex',
  props: {},
  data () {
    return {
      info: {
        email: '',
        password: '',
        platform: '1'
      }
    }
  },
  components: {},
  computed: {},
  created () {
    document.title = "SS-group"
  },
  watch: {},
  methods: {
    back () {
      this.$router.push({path: '/'})
    },
    goPage (path) {
      this.$router.push({path: path})
    },
    // 登录
    login () {
      let that = this
      if (!this.info.email) {
        this.$toast.warn('Please enter your username or email')
        return false
      }
      if (!this.info.password) {
        this.$toast.warn('Please enter your password.')
        return false
      }
      Api.post({
        url: 'login',
        data: that.info,
        success: function (res) {
          if (Number(res.errors) === 0 && res.data) {
            if (res.data.token) {
              that.$toast.success('Login succeeded!')
              window.localStorage.setItem('ssg-phone-token', res.data.token)
              that.getUserInfo()
            } else {
              that.$toast.fail(res.data.msg)
            }
          }
        }
      })
    },
    // 获取用户数据
    getUserInfo () {
      let that = this
      Api.post({
        url: 'users/info',
        success (res) {
          if (Number(res.errors) === 0 && res.data) {
            window.localStorage.setItem('ssg-phone-user-info', JSON.stringify(res.data))
            that.$router.push({path: '/list/goodsList'})
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.login-index>>>.nut-textinput input{border:1px solid #b0332f;}
.login-box{background-color:#fff;padding:0 20px;box-sizing:border-box;}
.login-item{display:block;}
.login-item .item-list{padding:10px 0;}
.login-item .item-list .list-title{font-size:14px;color:#333;line-height:24px;}
.operate-sign{font-size:14px;color:#b0332f;}
.sign-bottom{height:65px;background-color:#fff;border-color:#fff;}
.bottom-box{position:fixed;bottom:0;height:65px;max-width:750px;width:100%;margin:0 auto;}
.bottom-box .bottom-btn{margin:10px 20px;height:45px;line-height:45px;text-align:center;background-color:#b0332f;color:#fff;border-radius:10px;}
.bottom-text{text-align: center;margin-bottom:20px;color: #564363;font-size:14px;}
</style>
