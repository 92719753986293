<template>
  <div id="app">
    <router-view></router-view>
    <publicLogin></publicLogin>
  </div>
</template>

<script>
import publicLogin from "@/components/public/login.vue"
export default {
  name: 'App',
  components: {
    publicLogin
  },
  created () {},
  methods: {
  }
}
</script>

<style>
html,body{font-family:Arial,"Poppins",sans-serif;margin:0;padding:0;}
ul{margin:0;padding:0;list-style:none;outline:none;}
.wrapper a{display:inline-block;margin:0;padding:0;text-decoration:none;outline:none;}
.wrapper a:link,.wrapper a:visited,.wrapper a:hover,.wrapper a:active{text-decoration:none;outline:none;}
.wrapper p,.wrapper dl,.wrapper dd{margin:0;}
.pull-left{float:left!important;}
.pull-right{float:right!important;}
.text-right{text-align:right;}
.no-more{display:block;padding:15px 0;font-size:16px;color:#333;text-align:center;background-color:#fff;}
.alert-mes{position:fixed;top:50%;left:50%;z-index:2000;padding:15px;min-width:180px;max-width:240px;font-size:15px;color:#fff;text-align:center;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);-o-transform:translate(-50%,-50%);transform:translate(-50%,-50%);background:rgba(0,0,0,0.7);border-radius:5px;}
.el-upload .el-upload__input,.el-upload input[type=file]{display:none;}
.wrapper{background-color:#f5f5f5;margin:0 auto;max-width:720px;width:100%;overflow:hidden;}
.empty-img{display: block;width:100%;}
.clear{clear:both;}

/* 列表信息填写公共样式 */
.public-item{display:block;}
.public-item .item-list{padding:10px 0;border-bottom:1px solid #e6e6e6;}
.public-item .item-list .list-title{font-size:14px;color:#333;line-height:24px;}
.public-item .item-list .list-title .title-icon{color:#f20;}
.public-item .item-list .list-ipt{width:70%;display: inline-block;}
.public-item .item-list .list-btn{display: inline-block;width:30%;text-align:center;color:#b0332f;}
.public-item .item-list .list-selected{overflow:hidden;line-height:40px;border-top:4px;background-color:#f5f7fa;padding:0 10px;font-size:13px;color:#999;}
.public-item .item-list .list-selected .selected-option{float:right;margin-top:10px;color:#999;}
.public-item .item-list .list-imgs{padding:10px;}
.public-item .item-list .upload-img{display:block;width:100px;border-radius:10px;margin-right:5px;}
.public-item .item-list .upload-btn{display:inline-block;width:100px;line-height:100px;border-radius:10px;border:1px dashed #b0332f;text-align:center;font-size:24px;color:#b0332f;}
/* 学校列表 */
.school-item .school-item-list{margin:10px 12px 0;background-color:#fff;border-radius:12px;padding:12px 40px 12px 12px;overflow:hidden;position:relative;}
.school-item .school-item-list::before{content:'';display:block;border-top:1px solid #e6e6e6;width:10px;height:10px;border-right:2px solid #e6e6e6;position:absolute;right:22px;top:50%;transform:rotate(45deg) translate(0, -50%);}
.school-item .school-item-list .list-img{display: block;float:left;width:64px;height:64px;}
.school-item .school-item-list .list-info{margin-left:76px;}
.school-item .school-item-list .list-info .info-title{font-size:15px;color:#222;line-height:20px;max-height:40px;font-weight:600;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:5px;}
.school-item .school-item-list .list-info .info-text{font-size:12px;color:#999;line-height:32px;}
.school-item .school-item-list .list-info .info-lab{overflow:hidden;}
.school-item .school-item-list .list-info .lab-self{float:left;font-size:12px;padding:2px;background-color:#b0332fa6;color:#b0332f;margin-right:5px;margin-bottom:5px;border-radius:4px;}
/* 商品列表 */
.goods-item{padding:0 12px;background-color:#fff;}
.goods-item .goods-item-list{overflow:hidden;padding:10px 0;border-bottom:4px solid #b0332fa6;}
.goods-item .goods-item-list .list-img{float:left;position:relative;}
.goods-item .goods-item-list .list-img .img-self{display: block;width:120px;height:120px;}
.goods-item .goods-item-list .list-img .goods-issp{position:absolute;padding:2px 6px;left:0;top:0;font-size:12px;background-color:#b0332f70;color:#fff;border-radius:0 12px 0 12px;}
.goods-item .goods-item-list .list-img .goods-id{position:absolute;left:0;bottom:0;padding:0 5px;font-size:12px;text-align:center;background-color:#b0332fa6;width:100%;line-height:22px;color:#fff;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;box-sizing:border-box;}
.goods-item .goods-item-list .list-info{margin-left:140px;}
.goods-item .goods-item-list .list-info .info-title{font-size:12px;color:#a1a1a1;line-height:18px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:5px;}
.goods-item .goods-item-list .list-info .info-text{font-size:14px;color:#737373;margin-bottom:5px;}
.goods-item .goods-item-list .list-info .info-text .text-color{color:#ff6433;}
.goods-item .goods-item-list .list-info .info-text:last-child{margin-bottom:0;}
.goods-item .goods-item-list .list-info .info-text .text-btn{width:72px;display:block;background-color:#b0332f;text-align: center;color: #fff;font-size:12px;line-height:18px;border-radius:18px;}
/* 公共搜索 */
.popup-item{overflow-y:hidden;padding:30px 12px 0;height:100%;}
.popup-item .popup-list{margin-bottom:15px;}
.popup-item .popup-list .list-title{font-size:12px;color:#999;line-height:33px;margin-bottom:10px;}
.popup-item .popup-list .list-box{overflow: hidden;}
.popup-item .popup-list .list-box .list-select{float:left;margin-right:12px;margin-bottom:12px;padding:10px;background-color:#F2F2F2;color:#333;border-radius:8px;border:1px solid #f2f2f2;font-size:12px;}
.popup-item .popup-list .list-box .list-select.active{border-color:#b0332f;background-color:#b0332fa6;}
.popup-item .popup-list .list-box .list-ipt{width:40%;border-color:#b0332f;line-height:22px;border-radius:8px;padding:5px 10px;box-sizing:border-box;}
.popup-boox{height:48px;}
.popup-bos .popup-btm{position:fixed;width:90%;bottom:0;right:0;height:48px;overflow:hidden;}
.popup-bos .popup-btm .btm-btn{width:50%;float:left;line-height:48px;text-align:center;font-size:15px;color:#333;background-color:#fff;border-radius:0 0 0 20px;}
.popup-bos .popup-btm .btm-btn.search{background-color:#b0332f;color:#fff;border-radius:0;}
/* 详情公共头部 */
.content-top{position:relative;}
.content-top .top-img{display:block;width:100%;}
.content-top .top-nav{position:absolute;top:0;left:0;width:100%;overflow:hidden;padding:12px;box-sizing:border-box;}
.content-top .top-nav .nav-icon{display:block;width:32px;height:32px;background:url('~@/assets/images/detail-come-back.png') center no-repeat;background-size:32px;display:inline-block;}
.content-top .top-nav .nav-icon.share{background-image:url('~@/assets/images/detail-share.png');}
.content-top .top-nav .nav-icon.collect{background-image:url('~@/assets/images/detail-collect.png');}
.content-top .top-nav .nav-icon.rig{float:right;margin-left:10px;}
</style>
